import { useState } from "react";
import { signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { auth, db, Providers } from "../../config/firebase";
import { Button, Typography } from "@mui/material";
import { Google } from "@mui/icons-material";
import Center from "../utils/Center";
import { addDoc, collection, doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

interface Props { }

const AuthContainer = (props: Props) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [disabled, setDisabled] = useState(false);
  const createUserDocument = async () => {
    const userRef = doc(db, "users", auth.currentUser!.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const familyDocRef = await addDoc(collection(db, "families"), {
        name: '',
        owner: auth.currentUser!.uid
      });
      await setDoc(userRef, {
        email: auth.currentUser!.email,
        displayName: auth.currentUser!.displayName,
        firstName: auth.currentUser!.displayName?.split(' ')[0] || '',
        lastName: auth.currentUser!.displayName?.split(' ').slice(1).join(' ') || '',
        familyId: familyDocRef.id,
        isFamilyOwner: true,
        memberId: auth.currentUser!.uid,
        photoURL: auth.currentUser!.photoURL,
        metadata: {
          createdAt: serverTimestamp(),
          lastLogin: serverTimestamp(),
          provider: 'google'
        }

      });


      const memberRef = doc(db, 'families', familyDocRef.id, 'members', auth.currentUser!.uid);
      await setDoc(memberRef, {
        firstName: auth.currentUser!.displayName?.split(' ')[0] || '',
        lastName: auth.currentUser!.displayName?.split(' ').slice(1).join(' ') || '',
        displayName: auth.currentUser!.displayName,
        email: auth.currentUser!.email,
        role: 'owner',
        photoURL: auth.currentUser!.photoURL,
        metadata: {
          createdAt: serverTimestamp(),
          active: true
        }
      })

      // batch.set(employeeRef, {
      //   firstName: result.user.displayName?.split(' ')[0] || '',
      //   lastName: result.user.displayName?.split(' ').slice(1).join(' ') || '',
      //   email: googleEmail,
      //   role: invitation.data().role,
      //   photoURL: result.user.photoURL,
      //   metadata: {
      //     createdAt: serverTimestamp(),
      //     active: true
      //   }
      // });

      // batch.update(invitationRef, {
      //   status: 'ACCEPTED',
      //   metadata: {
      //     ...invitation.data().metadata,
      //     acceptedAt: serverTimestamp(),
      //     acceptedBy: result.user.uid
      //   }
      // });

      // await batch.commit();
    }
  }
  const signInWithGoogle = async () => {
    setDisabled(true);
    signInWithPopup(auth, Providers.google)
      .then(async () => {
        setDisabled(false);
        console.info("TODO: navigate to authenticated screen");
        navigate("/");
        await createUserDocument();
      })
      .catch((error) => {
        setErrorMessage(error.code + ": " + error.message);
        setDisabled(false);
      });
  };

  return (
    <Center height={"auto"}>
      <Button
        startIcon={<Google />}
        size="large"
        disabled={disabled}
        variant="contained"
        onClick={signInWithGoogle}
      >
        Sign In With Google
      </Button>
      <Typography sx={{ mt: 2 }} color={"red"}>
        {errorMessage}
      </Typography>
    </Center>
  );
};

export default AuthContainer;
