import Home from "../pages/Home";
import Login from "../pages/Login";
import Error from "../pages/Error";
import Profile from "../pages/Profile";
import TodoDetails from "../pages/TodoDetails";
import FCM from "../pages/FCM";

import * as ROUTES from "./../constants/routes";
import InviteUser from "../pages/InviteUser";
import { AcceptInvite } from "../pages/AcceptInvite";
import MamberDetails from "../pages/MemberDetails";
interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}

const routes: RouteType[] = [
  {
    path: ROUTES.HOME_PATH,
    component: Home,
    name: "Home Screen",
    protected: true,
  },
  {
    path: ROUTES.LOGIN_PATH,
    component: Login,
    name: "Login Screen",
    protected: false,
  },
  {
    path: ROUTES.MESSAGING_PATH,
    component: FCM,
    name: "Firebase Cloud Messaging",
    protected: true,
  },
  {
    path: ROUTES.PROFILE_PATH,
    component: Profile,
    name: "Login Screen",
    protected: true,
  },
  {
    path: ROUTES.TODO_DETAILS_PATH,
    component: TodoDetails,
    name: "Todo Details Screen",
    protected: true,
  },
  {
    path: "*",
    component: Error,
    name: "Error Screen",
    protected: false,
  },
  {
    path: ROUTES.INVITE_USER_PATH,
    component: InviteUser,
    name: "Invite User Screen",
    protected: true,
  },
  {
    path: ROUTES.ACCEPT_INVITE_PATH,
    component: AcceptInvite,
    name: "Invite User Screen",
    protected: false,
  },
  {
    path: ROUTES.MEMBER_DETAILS_PATH,
    component: MamberDetails,
    name: "Member Details Screen",
    protected: true,
  },
];

export default routes;
