// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

import { getMessaging } from "firebase/messaging";

import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs
} from 'firebase/firestore';

import {
  getAuth, 
  signInWithRedirect, 
  signInWithPopup, 
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from 'firebase/auth';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {
  // apiKey: "AIzaSyDBYMmy0ime_UTIES9MYZ7_VT8cvH0T2WU",
  // authDomain: "reactfirebase-b2dea.firebaseapp.com",
  // projectId: "reactfirebase-b2dea",
  // storageBucket: "reactfirebase-b2dea.appspot.com",
  // messagingSenderId: "520185133808",
  // appId: "1:520185133808:web:e8ac6843e9aea0307d2035",
  // measurementId: "G-QLW3YV1QF1",

  /*
  // Firebase configuration for Savings
  apiKey: "AIzaSyBdtXJ5kHcxQHvokAgAgCdASAVm82St_AM",
  authDomain: "savings-1d454.firebaseapp.com",
  projectId: "savings-1d454",
  storageBucket: "savings-1d454.firebasestorage.app",
  messagingSenderId: "520185133808",
  appId: "1:888997430564:web:a246c3ba320875bcd51fb1",
  measurementId: "G-QDFQ302068"
  */

  /*Firestore configs for Fizz */
  // apiKey: "AIzaSyBHQYO-0lsAtCnU3EDTkkKwGcxRAjVVmhg",
  // authDomain: "fizz-f7426.firebaseapp.com",
  // projectId: "fizz-f7426",
  // storageBucket: "fizz-f7426.firebasestorage.app",
  // messagingSenderId: "92247544486",
  // appId: "1:92247544486:web:4461cf23ba19a54806da67",
  // measurementId: "G-2614KGBEZL"

 /*Firestore configs for FinZ */
  apiKey: "AIzaSyDww8WJa9l6BEq03h-EYqaW7qZM6minzUQ",
  authDomain: "finz-85af3.firebaseapp.com",
  projectId: "finz-85af3",
  storageBucket: "finz-85af3.firebasestorage.app",
  messagingSenderId: "156708009199",
  appId: "1:156708009199:web:32b03fa9bc8f87107d971a",
  measurementId: "G-2GXBZ93BHE"
};

// Initialize Firebase
export const Firebase = initializeApp(firebaseConfig);
export const analytics = getAnalytics(Firebase);
export const auth = getAuth();
export const Providers = { google: new GoogleAuthProvider() };
export const db = getFirestore(Firebase);

const messaging = getMessaging(Firebase);
