import { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { query, collectionGroup, where, limit, getDocs } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { acceptInvitationWithGmail } from '../services/invitations'
interface Invitation {
    id: string;
    companyId: string;
    email: string;
    role: string;
    status: 'PENDING' | 'ACCEPTED' | 'EXPIRED';
    token: string;
    metadata: {
      createdAt: Date;
      expiresAt: Date;
      createdBy: string;
    };
  }
  
export function AcceptInvite() {
  const [searchParams] = useSearchParams();
  const [invitation, setInvitation] = useState<any>(null);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const token = searchParams.get('token');

  useEffect(() => {
    const verifyInvitation = async () => {
      if (!token) {
        setError('Invalid invitation link');
        setLoading(false);
        return;
      }

      try {
        // Query all invitations collections across all companies
        const invitationsRef = collectionGroup(db, 'invitations');
        const q = query(
          invitationsRef,
          where('token', '==', token),
          where('status', '==', 'PENDING'),
          limit(1)
        );

        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setError('Invalid or expired invitation');
          setLoading(false);
          return;
        }

        const invitationDoc = querySnapshot.docs[0];
        const companyId = invitationDoc.ref.parent.parent?.id;

        if (!companyId) {
          setError('Invalid company reference');
          setLoading(false);
          return;
        }

        const invitationData = invitationDoc.data() as Omit<Invitation, 'id' | 'companyId'>;
        
        setInvitation({
          id: invitationDoc.id,
          companyId,
          ...invitationData
        });

        setLoading(false);
      } catch (err) {
        console.error('Error verifying invitation:', err);
        setError('Error verifying invitation');
        setLoading(false);
      }
    };

    verifyInvitation();
  }, [token]);

  const handleGoogleSignIn = async () => {
    try {
      setLoading(true);
      await acceptInvitationWithGmail(invitation.id, invitation.companyId);
      navigate('/');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="accept-invite-container">
      <h1>Accept Invitation</h1>
      <p>Sign in with your Google account to accept the invitation</p>
      
      <button 
        onClick={handleGoogleSignIn}
        disabled={loading}
        className="google-sign-in-button"
      >
        Sign in with Google
      </button>
    </div>
  );
}