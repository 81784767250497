import React, { useContext, useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { AuthContext } from './auth/AuthContext';
import { Box, TextField, Button, Grid } from "@mui/material";

interface MembersListProps {

    onMemberClick?: (member: any) => void;

    // other props

}



const MembersList: React.FC<MembersListProps> = ({ onMemberClick, ...props }) => {
    const [members, setMembers] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const { currentUserDetails, currentUser } = useContext(AuthContext);
    const [currentUserMember, setCurrentUserMember] = useState<any | null>(null);
    useEffect(() => {
        const fetchMembers = async () => {
            const auth = getAuth();
            const user = auth.currentUser;
            if (user) {
                const db = getFirestore();

                const membersRef = collection(db, 'families/' + currentUserDetails.familyId + '/members');
                const q = query(membersRef);
                const querySnapshot = await getDocs(q);
                const membersList: any[] = [];
                const accountsRef = collection(db, 'families/RQJS5VLRBoepAwsUDscw/members/03OIy88G7tSQsE5r5Wfnyq0wjPh2/accounts');
                const accountsSnapshot = await getDocs(accountsRef);
                accountsSnapshot.forEach((accountDoc) => {
                    const accountData = accountDoc.data();
                    console.log('accountData.balance', accountData.balance);
                });
                // querySnapshot.forEach(async (doc) => {
                //     if (doc.id === user.uid) {
                //         setCurrentUserMember({ id: doc.id, ...doc.data() });
                //     }
                //     else {
                //         //const accountsRef = collection(db, `families/${currentUserDetails.familyId}/members/${doc.id}/accounts`);
                //         // const refValue = `families/${currentUserDetails.familyId}/members/${doc.id}/accounts`;
                //         // console.log('refValue', refValue);
                //         // const accountsRef = collection(db, refValue);
                //         //  const accountsSnapshot = await getDocs(accountsRef);
                //         // const accountsList: any[] = [];
                //         // let totalBalance = 0;
                //         // accountsSnapshot.forEach((accountDoc) => {
                //         //     const accountData = accountDoc.data();
                //         //     totalBalance += accountData.balance || 0;
                //         // });
                //         // membersList.push({ id: doc.id, ...doc.data(), accounts: accountsList, balance: totalBalance });
                //         // // accountsSnapshot.forEach((accountDoc) => {

                //         // //     accountsList.push({ id: accountDoc.id, ...accountDoc.data() });
                //         // // });
                //         // membersList.push({ id: doc.id, ...doc.data(), accounts: accountsList });
                //         membersList.push({ id: doc.id, ...doc.data() });
                //     }

                // });
                await Promise.all(
                    querySnapshot.docs.map(async (doc) => {
                        if (doc.id !== user.uid) {
                            const accountsRef = collection(db, `families/${currentUserDetails.familyId}/members/${doc.id}/accounts`);
                            const accountsSnapshot = await getDocs(accountsRef);
                            const accountsList: any[] = [];
                            let totalBalance = 0;
                            accountsSnapshot.forEach((accountDoc) => {
                                const accountData = accountDoc.data();
                                totalBalance += accountData.balance || 0;
                                accountsList.push({ id: accountDoc.id, ...accountData });
                            });
                            membersList.push({ id: doc.id, ...doc.data(), accounts: accountsList, balance: totalBalance });
                        }
                    })
                );
                setMembers(membersList);
            }

            setLoading(false);
        };
        if (currentUserDetails) {
            fetchMembers();
        }
    }, [currentUserDetails]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Box display={"flex"}
            alignItems={"center"}
            flexDirection={"column"}
            width={"100%"}
        // maxWidth={"400px"}
        // boxShadow={2}
        // margin={3}
        >
            <h2>Family Members</h2>
            {/* <ul> */}
            {/* {currentUserMember && (
                    <Box
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        boxShadow={2}
                        margin={3}
                        padding={2}
                        
                        bgcolor="lightgray"
                        // width={"90vw"}
                        maxWidth={"400px"}
                    >
                        <img src={currentUserMember.photoURL} alt={currentUserMember.firstName} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                        <h3>{currentUserMember.firstName} {currentUserMember.lastName} (You)</h3>
                    </Box>
                )} */}
            {members.map((member) => (
                // <li key={member.id}>{member.name}</li>
                <>
                    <Box
                        key={member.id}
                        display={"flex"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        style={{ border: '1px solid gray', borderRadius: '10px' }}
                        // boxShadow={2}
                        margin={3}
                        padding={2}
                        // bgcolor="lightgray"
                        // width={"90vw"}
                        width="80%"
                        onClick={() => {
                            onMemberClick && onMemberClick(member);
                        }}

                    >
                        <Box flexDirection={"row"} display={"flex"} gap={"6px"} width={"100%"}>
                            {member?.photoURL && (<img src={member?.photoURL} alt={member.firstName} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />)}
                            {member.firstName && (<h3 style={{ marginTop: 'auto', marginBottom: 'auto' }}>{member.firstName} {member.lastName}</h3>)}
                        </Box>
                        <hr style={{ width: '100%' }}></hr>
                        <Box fontSize={"20px"} fontWeight={"bold"} color={"blue"}>Balance: {member.balance}</Box>
                        {/* <h3>{member.firstName} {member.lastName}</h3> */}

                    </Box>
                    {/* <Box
                        key={member.id}
                        // display={"flex"}
                        display={"none"}
                        alignItems={"center"}
                        flexDirection={"column"}
                        boxShadow={2}
                        margin={3}
                        padding={2}
                        // bgcolor="lightgray"
                        // width={"90vw"}
                        maxWidth={"400px"}
                        onClick={()=>{
                            onMemberClick && onMemberClick(member);
                        }}
                    >
                        <img src={member.photoURL} alt={member.firstName} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />
                        <h3>{member.firstName} {member.lastName} {member.id === currentUser?.uid ? '(You)' : ''}</h3>
                        
                    </Box> */}
                </>
            ))}
            {/* </ul> */}
        </Box>
    );
};

export default MembersList;