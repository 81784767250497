import { JSXElementConstructor, Key, ReactElement, ReactFragment, ReactPortal, useContext, useEffect, useState } from "react";
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import { useParams } from "react-router-dom";
import Center from "../components/utils/Center";
import { Box, Button, Input, TextField } from "@mui/material";
// import { Button } from "react-bootstrap";
// import { Button, TextField, Box, Typography, CircularProgress, Alert } from "@mui/material";
import { AuthContext } from "../components/auth/AuthContext";

import cashIcon from "../assets/cash-icon.png";
import { TRANSACTIONS_TYPES } from "../constants/accountsConstants";

interface Props {
  member: any
  onBackClick?: () => void
  showAccount?: boolean
}

const MamberDetails = ({ member, onBackClick, showAccount = true }: Props) => {
  console.log('member', member)
  const { currentUserDetails, currentUser } = useContext(AuthContext);
  const [account, setAccount] = useState<any>(null);
  const [valueToAdd, setValueToAdd] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionList, setTransactionList] = useState<any>(null);
  useEffect(() => {
    const fetchAccounts = async () => {
      const userId = member.id ?? member.memberId;
      console.log('memberAcccountRefPath', 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
      const memberAccountsRef = collection(db, 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
      const q = query(memberAccountsRef);
      const querySnapshot = await getDocs(q);
      console.log('querySnapshot accounts', querySnapshot);
      querySnapshot.forEach((doc) => {
        console.log('memberAccountsRef doc', doc.id, '=>', doc.data());
        setAccount({ ...doc.data(), id: doc.id });

      });
    }
    showAccount && fetchAccounts();
  }, [])
  const fetchTransactions = async () => {
    const userId = member.id ?? member.memberId;
    const transactionsPath = `families/${currentUserDetails.familyId}/members/${userId}/accounts/${account.id}/transactions`;
    console.log('transactionsPath', transactionsPath);
    const transactionsRef = collection(db, transactionsPath);
    const q = query(transactionsRef);
    const querySnapshot = await getDocs(q);
    console.log('querySnapshot accounts', querySnapshot);
    const transactions: any[] = [];
    querySnapshot.forEach((doc) => {
      console.log('memberAccountsRef doc', doc.id, '=>', doc.data());
      transactions.push({ ...doc.data(), id: doc.id });
      //setAccount({ ...doc.data(), id: doc.id });

    });
    transactions.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds);
    setTransactionList(transactions);
    console.log('transactions', transactions);
  }
  useEffect(() => {
    account && fetchTransactions();
  }, [account])
  const onDepositClick = async () => {
    setIsLoading(true);
    //deposit(valueToAdd);
    await updateBalance(valueToAdd);
    await addTransaction(valueToAdd, TRANSACTIONS_TYPES.DEPOSIT);
    setIsLoading(false);
  }

  const onWithdrawlClick = async () => {
    setIsLoading(true);
    await updateBalance(-valueToAdd);
    await addTransaction(valueToAdd, TRANSACTIONS_TYPES.WITHDRAWAL);
    setIsLoading(false);
    //withdrawl(valueToAdd);
  }
  // const deposit = (amount:number) => {

  // }
  // const withdrawl = (amount:number) => {

  // }
  const addTransaction = async (amount: number, type: string) => {
    const userId = member.id ?? member.memberId;
    const transactionsPath = `families/${currentUserDetails.familyId}/members/${userId}/accounts/${account.id}/transactions`;
    console.log('transactionsPath', transactionsPath);
    const transactionsRef = collection(db, transactionsPath);
    const transaction = {
      amount,
      type,
      createdAt: new Date()
    }
    await addDoc(transactionsRef, transaction);
    //const docRef = await doc(transactionsRef);
    //await updateDoc(docRef, transaction);
  }
  const updateBalance = async (amount: number) => {
    const userId = member.id ?? member.memberId;
    const accountPath = `families/${currentUserDetails.familyId}/members/${userId}/accounts/${account.id}`;
    console.log('accountPath', accountPath);
    const memberAccountDocRef = doc(db, accountPath);
    await updateDoc(memberAccountDocRef, { balance: account.balance + amount });
    setAccount({ ...account, balance: account.balance + amount });
  }
  return (
    <>

      <Box
        key={member.id}
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        boxShadow={2}
        margin={3}
        padding={2}
        // bgcolor="lightgray"
        // width={"90vw"}
        maxWidth={"400px"}

      >
        {member?.photoURL && (<img src={member?.photoURL} alt={member.firstName} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />)}
        {member.firstName && (<h3>{member.firstName} {member.lastName}</h3>)}
        {/* <h3>{member.firstName} {member.lastName}</h3> */}
        {account && (
          <Box
            width={"100%"}
            flexGrow={1}
            display={"flex"}
          >
            <Box>
              <h4>Account: Cash</h4>
              <p>Balance: {account.balance}</p>
            </Box>
            <img src={cashIcon} alt="cash" width={80} height={80} style={{ marginLeft: 'auto' }} />
            {/* <h4>Account: Cash</h4>
            <p>Balance: {account.balance}</p> */}
          </Box>
        )}
        {onBackClick && currentUserDetails.isFamilyOwner && (<Button onClick={onBackClick}>Back</Button>)}
        {account && (
          <Box
            flexDirection={"column"}
            display={"flex"}
            gap={"6px"}
            width={"100%"}
          >
            <TextField
              style={{ marginTop: 10 }}
              error={false}
              fullWidth
              id="outlined-search"
              label="Amount"
              type="number"
              value={valueToAdd === 0 ? '' : valueToAdd}
              // placeholder='Amount'
              onChange={(e) => setValueToAdd(Number(e.target.value))}
            />
            {/* <Input
              placeholder="Amount"
              type="number"
            // onChange={(e) => setAccount({ ...account, balance: account.balance + Number(e.target.value) })}
            /> */}
            <Box flexDirection={"row"}
              display={"flex"}
              gap={"6px"}

            >
              <Button
                style={{ flexGrow: 1 }}
                variant="contained"
                onClick={onDepositClick}
                loading={isLoading}
                disabled={isLoading}
              // onClick={() => {
              //   const inputElement = document.querySelector('input[type="number"]') as HTMLInputElement;
              //   setAccount({ ...account, balance: account.balance + Number(inputElement?.value) });
              // }}>
              >
                Deposit
              </Button>
              <Button
                variant="contained"
                onClick={onWithdrawlClick}
                loading={isLoading}
                disabled={isLoading}
              // onClick={() => {
              //   const inputElement = document.querySelector('input[type="number"]') as HTMLInputElement;
              //   setAccount({ ...account, balance: account.balance + Number(inputElement?.value) });
              // }}>
              >
                Withdrawl
              </Button>
            </Box>

          </Box>
        )}
        {/* {account && (
          <div>
            <Button onClick={() => setAccount({ ...account, balance: account.balance + 1 })}>+</Button>
            <Button onClick={() => setAccount({ ...account, balance: account.balance - 1 })}>-</Button>
          </div>
        )} */}
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        flexDirection={"column"}
        boxShadow={2}
        margin={3}
        padding={2}
        // bgcolor="lightgray"
        // width={"90vw"}
        maxWidth={"400px"}

      >
        <h3>Transactions</h3>
        {transactionList && transactionList.length > 0 ? (
            <Box width={"100%"} maxHeight={"300px"} overflow={"auto"}>
            <Box display={"flex"} borderBottom={"1px solid black"} position={"sticky"} top={0} bgcolor={"white"} zIndex={1}>
              <Box flex={1} padding={1} fontWeight={"bold"}>Date</Box>
              <Box flex={1} padding={1} fontWeight={"bold"}>Type</Box>
              <Box flex={1} padding={1} fontWeight={"bold"} textAlign={"right"}>Value</Box>
            </Box>

            {transactionList.map((transaction: { id: Key | null | undefined; createdAt: { seconds: number; }; type: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; amount: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | ReactFragment | ReactPortal | null | undefined; }) => (
              <Box key={transaction.id} display={"flex"} borderBottom={"1px solid lightgray"}>
              <Box flex={1} padding={1}>{new Date(transaction.createdAt?.seconds * 1000).toLocaleDateString()}</Box>
              <Box flex={1} padding={1}>{transaction.type}</Box>
              <Box flex={1} padding={1} textAlign={"right"} color={transaction.type===TRANSACTIONS_TYPES.DEPOSIT?"green":"red"}>{transaction.amount}</Box>
              </Box>
            ))}
            </Box>
        ) : (
          <p>No transactions found</p>
        )}
      </Box>
    </>
  );
};

export default MamberDetails;
