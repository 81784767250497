import React, { createContext, useContext, useState, useEffect } from 'react';
import { User } from 'firebase/auth';
import { auth, db } from '../../config/firebase';
import { collection, doc, getDoc } from 'firebase/firestore';

interface AuthContextType {
  currentUser: User | null;
  currentUserDetails: any | null;
  loading: boolean;
}

export const AuthContext = createContext<AuthContextType>({
  currentUser: null,
  currentUserDetails: null,
  loading: true
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserDetails, setCurrentUserDetails] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const loadUserDetails = async () => {
    const docRef = doc(collection(db, "users"), auth.currentUser?.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      //const userDate = docSnap.data();

      setCurrentUserDetails(docSnap.data())
    } else {
      console.log("No such document!");
    }
  }
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => { unsubscribe(); };
  }, []);
  useEffect(() => { 
    if (currentUser) {
      loadUserDetails();
            const checkUserAccount = async () => {
              const familyId = currentUser?.uid; // Assuming familyId is the same as userId
              const accountRef = doc(collection(db, `families/${familyId}/accounts`), currentUser?.uid);
              const accountSnap = await getDoc(accountRef);
              if (accountSnap.exists()) {
                console.log('Account exists:', accountSnap.data());
              } else {
                console.log('No account found for this user in the family.');
              }
            };
            checkUserAccount();
    }
  }, [currentUser])
  //   useEffect(() => {
  //     const unsubscribe = auth.onAuthStateChanged(user => {
  //       setCurrentUser(user);
  //       setLoading(false);
  //     });

  //     return unsubscribe;
  //   }, []);

  const value = {
    currentUser,
    loading,
    currentUserDetails
  };
  return (

    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// export default AuthContext;
