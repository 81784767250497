import React, { useContext, useEffect, useState } from 'react';
import { db } from "../config/firebase"; // Adjust the import based on your firebase configuration
import { collection, getDocs, query } from 'firebase/firestore';
import { AuthContext } from '../components/auth/AuthContext';
import { Box, Button } from '@mui/material';
import cashIcon from "../assets/cash-icon.png";
import cashDigitalIcon from "../assets/cash-digital-icon.png";
import { Label } from '@mui/icons-material';
import MemberAccountDetails from './MemberAccountDetails';
interface Props {
    member: any
    onBackClick?: () => void
}
interface Account {
    id: string;
    // name: string;
    // email: string;

    type: string;
    balance: number;
}

const MemberAccountsList: React.FC<Props> = ({ member, onBackClick }) => {
    console.log('member',member)
    const { currentUserDetails, currentUser } = useContext(AuthContext);
    const [totalBalance, setTotalBalance] = useState<number>(0);
    const [accountsList, setAccountsList] = useState<Account[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
    const fetchAccounts = async () => {
        try {
            const userId = member.id ?? member.memberId;
            // console.log('memberAcccountRefPath', 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
            const memberAccountsRef = collection(db, 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
            const q = query(memberAccountsRef);
            const querySnapshot = await getDocs(q);
            // console.log('querySnapshot accounts', querySnapshot);
            const accounts: Account[] = []
            querySnapshot.forEach((doc) => {
                accounts.push({ ...doc.data(), id: doc.id } as Account);
            });
            let balance = 0
            accounts.forEach(account => {
                balance += account.balance;
            }
            )
            setTotalBalance(balance);
            setAccountsList(accounts);
            // const querySnapshot = await getDocs(collection(db, 'accounts'));
            // const accountsList: Account[] = querySnapshot.docs.map(doc => ({
            //     id: doc.id,
            //     ...doc.data()
            // })) as Account[];
            // setAccounts(accountsList);
        } catch (error) {
            console.error('Error fetching accounts: ', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        // const fetchAccounts = async () => {
        //     try {
        //         const userId = member.id ?? member.memberId;
        //         console.log('memberAcccountRefPath', 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
        //         const memberAccountsRef = collection(db, 'families/' + currentUserDetails.familyId + '/members/' + userId + '/accounts');
        //         const q = query(memberAccountsRef);
        //         const querySnapshot = await getDocs(q);
        //         console.log('querySnapshot accounts', querySnapshot);
        //         const accounts: Account[] = []
        //         querySnapshot.forEach((doc) => {
        //             accounts.push({ ...doc.data(), id: doc.id } as Account);
        //         });
        //         let balance = 0
        //         accounts.forEach(account => {
        //             console.log('---account---', account);
        //             balance += account.balance;
        //         }
        //         )
        //         setTotalBalance(balance);
        //         setAccountsList(accounts);
        //         // const querySnapshot = await getDocs(collection(db, 'accounts'));
        //         // const accountsList: Account[] = querySnapshot.docs.map(doc => ({
        //         //     id: doc.id,
        //         //     ...doc.data()
        //         // })) as Account[];
        //         // setAccounts(accountsList);
        //     } catch (error) {
        //         console.error('Error fetching accounts: ', error);
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        fetchAccounts();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }
    const onAccountBackClick = () => {
        fetchAccounts();
        setSelectedAccount(null);
        // onBackClick && onBackClick();
    }
    const onAccountBalanceChanged = (account: Account) => {
        // console.log('onAccountBalanceChanged', account);
        fetchAccounts();
    }
    return (
        <div>
            {onBackClick && currentUserDetails.isFamilyOwner && (<Button onClick={onBackClick}>Back</Button>)}
            <Box
                key={member.id}
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
                boxShadow={2}
                margin={3}
                padding={2}
                // bgcolor="lightgray"
                // width={"90vw"}
                maxWidth={"400px"}

            >
                {member?.photoURL && (<img src={member?.photoURL} alt={member.firstName} style={{ width: '40px', height: '40px', borderRadius: '50%' }} />)}
                {member.firstName ? (<h3>{member.firstName} {member.lastName}</h3>):(<h3>{member.displayName}</h3>)}
                <Box fontSize={"20px"} fontWeight={"bold"} color={"blue"}>{totalBalance}</Box>
                {/* <h3>{member.firstName} {member.lastName}</h3> */}

            </Box>



            {selectedAccount && (
                <MemberAccountDetails selectedAccount={selectedAccount} member={member} onBackClick={onAccountBackClick} onAccountBalanceChanged={onAccountBalanceChanged} />
            )}
            {!selectedAccount && (
                <Box style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}
                    boxShadow={2}
                    margin={3}
                    padding={2}
                >
                    Accounts
                    {accountsList.map(account => (
                        <Box key={account.id} style={{ border: '1px solid #ccc', padding: '16px', borderRadius: '8px', width: '100%' }} flexDirection={"row"} display={"flex"} gap={"6px"}
                            onClick={() => setSelectedAccount(account)}
                        >
                            <img src={account.type === 'CASH' ? cashIcon : cashDigitalIcon} alt={account.type} width={40} height={40} style={{ marginRight: '16px' }} />
                            <Box marginTop={"auto"} marginBottom={"auto"} >{account.type === "CASH" ? "Cash" : "Digital Cash"}</Box>
                            <Box marginLeft="auto" marginTop={"auto"} marginBottom={"auto"} color={"green"} fontSize={"20px"}>{account.balance}</Box>
                            {/* <p>Type: {account.type}</p>
                        <p>Balance: {account.balance}</p> */}
                        </Box>
                    ))}
                </Box>
            )}
            {/* <ul>
                {accountsList.map(account => (
                    <li key={account.id}>
                        <p>Type: {account.type}</p>
                        <p>balance: {account.balance}</p>
                    </li>
                ))}
            </ul> */}
        </div>
    );
};

export default MemberAccountsList;