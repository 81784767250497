import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  where,
  limit,
  getDocs,
  writeBatch,
  doc,
  getDoc,
  collectionGroup
} from 'firebase/firestore';
import {
  GoogleAuthProvider,
  signInWithPopup,
  sendSignInLinkToEmail
} from 'firebase/auth';
import { nanoid } from 'nanoid';
import { db, auth } from '../config/firebase';

export async function inviteMember(familtyId: string, email: string, role: string) {
  try {
    const secureToken = nanoid(32);

    const invitationRef = collection(db, 'families', familtyId, 'invitations');
    const invitation = await addDoc(invitationRef, {
      email,
      role,
      status: 'PENDING',
      token: secureToken,
      metadata: {
        createdAt: serverTimestamp(),
        createdBy: auth.currentUser?.uid,
        expiresAt: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000),
      }
    });

    const actionCodeSettings = {
      url: `${window.location.origin}/accept-invite?token=${secureToken}`,
      handleCodeInApp: true,
    };

    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    return invitation.id;
  } catch (error) {
    console.error('Error inviting employee:', error);
    throw error;
  }
}

export async function acceptInvitationWithGmail(invitationId: string, familyId: string) {
  try {
    const invitationRef = doc(db, 'families', familyId, 'invitations', invitationId);
    const invitation = await getDoc(invitationRef);

    if (!invitation.exists() || invitation.data().status !== 'PENDING') {
      throw new Error('Invalid or expired invitation');
    }

    const invitedEmail = invitation.data().email;

    const googleProvider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, googleProvider);
    const googleEmail = result.user.email;

    if (googleEmail !== invitedEmail) {
      await auth.signOut();
      throw new Error('Please sign in with the email address that received the invitation');
    }

    const batch = writeBatch(db);

    const userRef = doc(db, 'users', result.user.uid);
    batch.set(userRef, {
      email: googleEmail,
      familyId,
      memberId: result.user.uid,
      displayName: result.user.displayName,
      photoURL: result.user.photoURL,
      firstName: result.user.displayName?.split(' ')[0] || '',
      lastName: result.user.displayName?.split(' ').slice(1).join(' ') || '',
      metadata: {
        createdAt: serverTimestamp(),
        lastLogin: serverTimestamp(),
        provider: 'google'
      }
    });

    const memberRef = doc(db, 'families', familyId, 'members', result.user.uid);
    batch.set(memberRef, {
      firstName: result.user.displayName?.split(' ')[0] || '',
      lastName: result.user.displayName?.split(' ').slice(1).join(' ') || '',
      displayName: result.user.displayName,
      email: googleEmail,
      role: invitation.data().role,
      photoURL: result.user.photoURL,
      metadata: {
        createdAt: serverTimestamp(),
        active: true
      }
    });
    const accountRef = doc(collection(db, 'families', familyId, 'members', result.user.uid, 'accounts'));
    const cashAccountRef = doc(collection(db, 'families', familyId, 'members', result.user.uid, 'accounts'));
    batch.set(cashAccountRef, {
      balance: 0,
      memberId: result.user.uid,
      type: 'CASH',
      name: 'Cash Account'
    });

    const digitalCashAccountRef = doc(collection(db, 'families', familyId, 'members', result.user.uid, 'accounts'));
    batch.set(digitalCashAccountRef, {
      balance: 0,
      memberId: result.user.uid,
      type: 'DIGITAL_CASH',
      name: 'Digital Cash Account'
    });
    // batch.set(accountRef, {
    //   balance: 0,
    //   memberId: result.user.uid,
    //   type: 'CASH',
    //   name: 'Cash Account'
    // });
    // batch.set(accountRef, {
    //   balance: 0,
    //   memberId: result.user.uid,
    //   type: 'DIGITAL_CASH',
    //   name: 'Digital Cash Account'
    // });
    //const memberAccountRef = doc(db, 'families', familyId, 'accounts');

    batch.update(invitationRef, {
      status: 'ACCEPTED',
      metadata: {
        ...invitation.data().metadata,
        acceptedAt: serverTimestamp(),
        acceptedBy: result.user.uid
      }
    });

    await batch.commit();
    return result.user;
  } catch (error) {
    console.error('Error accepting invitation with Gmail:', error);
    throw error;
  }
}