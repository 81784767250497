import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../config/firebase";
import { getAuth, isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import path from "path";
interface Props {
  children: React.ReactNode;
}


const AuthChecker = ({ children }: Props) => {
  const navigate = useNavigate();
  const auth = getAuth();
  const checkIfInvited = async () => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(auth, email='', window.location.href)
        .then((result) => {
          console.log('result', result);
          console.log('email', email);
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user by importing getAdditionalUserInfo
          // and calling it with result:
          // getAdditionalUserInfo(result)
          // You can access the user's profile via:
          // getAdditionalUserInfo(result)?.profile
          // You can check if the user is new or existing:
          // getAdditionalUserInfo(result)?.isNewUser
        })
        .catch((error) => {
          console.log('error', error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    }
  }
  useEffect(() => {
    //checkIfInvited();
    const currentPath = window.location.pathname;
    // if (currentPath.includes('accept-invite')) {
    //   checkIfInvited();
    // }
    if (!auth.currentUser && !currentPath.includes('accept-invite')) {
      navigate("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

export default AuthChecker;
