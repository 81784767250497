// common
export const ERROR_PATH = "*";

// pages
export const HOME_PATH = "/";
export const LOGIN_PATH = "/login";
export const MESSAGING_PATH = "/messaging";
export const PROFILE_PATH = "/profile";
export const TODO_DETAILS_PATH = "/todos/:id";
export const MEMBER_DETAILS_PATH = "/members/:id";
export const INVITE_USER_PATH = "/invite-user";
export const ACCEPT_INVITE_PATH = "/accept-invite";
