import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { collection, doc, getDoc, onSnapshot } from "firebase/firestore";
import { auth, db } from "../config/firebase";
import { useParams } from "react-router-dom";
import Center from "../components/utils/Center";
import { Button, TextField, Box, Typography, CircularProgress, Alert } from "@mui/material";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";
import { inviteMember } from "../services/invitations";
import { HOME_PATH } from "../constants/routes";

interface Props { }

const InviteUser = ({ }: Props) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [inviteSuccess, setInviteSuccess] = useState(false)
  const auth = getAuth();
  //console.log('auth', auth.currentUser)

  useEffect(() => {
    const loadUserDetails = async () => {
      const docRef = doc(collection(db, "users"), auth.currentUser?.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setUserDetails(docSnap.data())
        console.log('userDetails', docSnap.data())
      } else {
        console.log("No such document!");
      }
    }
    if (!userDetails)
      loadUserDetails()
  }, [])
  const onInviteUser = async () => {
    setLoading(true);
    await inviteMember(userDetails.familyId, email, 'child');
    // const actionCodeSettings = {
    //   url: window.location.origin,
    //   handleCodeInApp: true
    // };

    // try {
    //   await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    //   window.localStorage.setItem('emailForSignIn', email);
    //   console.log("Invitation email sent successfully!");
    //   //alert("Invitation email sent successfully!");
    // } catch (error) {
    //   console.error("Error sending email:", error);
    //   //alert("Error sending invitation email. Please try again.");
    // }
    //setEmail('')
    setInviteSuccess(true)
    setEmail('')
    setLoading(false);
  }
  return (
    // <Center height={"85vh"}>
    <>
    <Button
      onClick={() => navigate(HOME_PATH)}
      style={{ marginBottom: 10 }}
    >
      {'<'} Back to Home
    </Button>
      <Box
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        margin={3}
      // height={"85vh"}
      >
        <Typography variant="h4">Add/Invite a User</Typography>
        <TextField
          style={{ marginTop: 10 }}
          error={false}
          fullWidth
          id="outlined-search"
          label="Email"
          type="email"
          placeholder='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button onClick={onInviteUser} variant="contained" color="primary" style={{ marginTop: 10 }}>Add/Invite</Button>
        {loading && <CircularProgress />}
        
        {!loading && inviteSuccess && (
          <Alert
            severity="success"
            sx={{
              marginTop: 2,
              position: 'absolute',
              animation: 'fadeOut 3s forwards'
            }}
            onAnimationEnd={() => setInviteSuccess(false)}
          >
            Invitation sent successfully!
          </Alert>
        )}
        {/* {!loading && email === '' && (
        <Alert
          severity="success" 
          sx={{
            marginTop: 2,
            position: 'absolute',
            animation: 'fadeOut 3s forwards'
          }}
        >
          Invitation sent successfully!
        </Alert>
      )} */}
        <style>
          {`
          @keyframes fadeOut {
            0% { opacity: 1; }
            70% { opacity: 1; }
            100% { opacity: 0; }
          }
        `}
        </style>
      </Box>
    </>
    // </Center>
  );
};
export default InviteUser;